<template>
  <b-card no-body class="text-center">
    <HTMLTitle :title="$t('system.about.page_title')"></HTMLTitle>

    <card-header :title="$t('system.about.page_title')" :icon="['fas', 'info-circle']"></card-header>

    <b-card-title>
      <h1 :class="colorVariant('text-')">
        <b-img :src="imgUrl" :alt="appName" style="max-width: 50px;" />&nbsp;
        <strong>{{ appName }}</strong>
      </h1>
    </b-card-title>
    <b-card-text>
      <b-link class="card-link" :class="colorVariant('text-')" :href="this.$i18n.t('common.contact.mail_link')" target="_blank">
        <feather-icon icon="MailIcon" size="16" class="mr-50" />
        <span>{{ this.$i18n.t('common.contact.mail') }}</span>
      </b-link><br/><br />
      <b-button :variant="colorVariant('outline-')" :href="this.$i18n.t('common.contact.facebook_link')" target="_blank">
        <feather-icon icon="FacebookIcon" size="16" />
        <span class="d-none d-md-inline">&nbsp;{{ this.$i18n.t('common.contact.facebook') }}</span>
      </b-button>&nbsp;
      <b-button :variant="colorVariant('outline-')" :href="this.$i18n.t('common.contact.youtube_link')" target="_blank">
        <feather-icon icon="YoutubeIcon" size="16" />
        <span class="d-none d-md-inline">&nbsp;{{ this.$i18n.t('common.contact.youtube') }}</span>
      </b-button>&nbsp;
      <b-button :variant="colorVariant('outline-')" :href="this.$i18n.t('common.contact.telegram_link')" target="_blank">
        <feather-icon icon="SendIcon" size="16" />
        <span class="d-none d-md-inline">&nbsp;{{ this.$i18n.t('common.contact.telegram') }}</span>
      </b-button>
    </b-card-text>
    <b-card-text>
      <b-badge :variant="colorVariant('')">{{ this.$i18n.t('system.about.version') }} {{ form.version }}</b-badge>
    </b-card-text>
    <b-card-text v-if="form.version != server_version">
      <b-badge variant="danger">{{ this.$i18n.t('system.about.available_update') }}: {{ server_version }}</b-badge>
      <br /><br />
      <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" :variant="colorVariant('outline-')" @click="doUpdate" :disabled="formSubmitted">
        <feather-icon v-if="!formSubmitted" icon="DownloadCloudIcon" size="16" class="mr-50" />
        <b-spinner v-if="formSubmitted" class="mr-50" :type="spinnerType" style="width: 16px; height: 16px;" />
        <span class="align-middle">{{ this.$i18n.t('system.about.update_version') }}</span>
      </b-button>
    </b-card-text>
    <footer :class="colorVariant('text-')">
      {{ this.$i18n.t('footer.simple_text_1', { copyright: '©' + new Date().getFullYear() }) }} - {{ this.$i18n.t('footer.company_id') }}<br/>
      {{ this.$i18n.t('footer.simple_text_2') }}
    </footer>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BButton, BCardTitle, BImg, BBadge, BLink, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import defaultConfig from '@core-md/config'
import update from '@core-md/mixins/update'
import Crypt from '@core-md/utils/crypt'
import supportClient from '@core-md/services/support/client'
import handleAxiosException from '@core-md/mixins/errors'
import { $themeConfig } from '@themeConfig'
import HTMLTitle from '@core-md/layouts/components/HTMLTitle.vue'
import CardHeader from '@core-md/components/cards/CardHeader.vue'
import variants from '@core-md/mixins/variants'
import store from '@/store/index'

export default {
  components: {
    BCard,
    BCardText,
    BCardTitle,
    BButton,
    BImg,
    BBadge,
    BLink,
    BSpinner,
    HTMLTitle,
    CardHeader,
  },
  directives: {
    Ripple,
  },
  mixins: [
    update,
    handleAxiosException,
    variants,
  ],
  data() {
    return {
      form: {
        version: defaultConfig.VERSION_ID,
      },
      server_version: defaultConfig.VERSION_ID,
      updateTimer: null,
      formSubmitted: false,
    }
  },
  setup() {
    const { appName, appLogoImage, appLogoImageDark } = $themeConfig.app

    const { spinnerType } = $themeConfig.layout

    return {
      appName,
      appLogoImage,
      appLogoImageDark,
      spinnerType,
    }
  },
  mounted() {
    this.checkForUpdate()
  },
  beforeDestroy() {
    clearTimeout(this.updateTimer)
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        return this.appLogoImageDark
      }
      return this.appLogoImage
    },
  },
  methods: {
    checkForUpdate(showMessage = true) {
      const token = Crypt.signToken(this.form)
      const request = token
      supportClient.version({
        request,
      })
        .then(response => {
          const result = Crypt.decrypt(response.data.result)
          this.server_version = result
          if (this.server_version === this.form.version) {
            if (showMessage) {
              this.$root.showMessageSuccess(this.$i18n.t('system.about.messages.already_updated_detail'), this.$i18n.t('system.about.messages.already_updated'))
            }
          } else {
            this.$root.showMessageWarning(this.$i18n.t('system.about.messages.update_found_detail'), this.$i18n.t('system.about.messages.update_found'))
          }
        })
        .catch(error => {
          this.handleAxiosException(error, 'system.about.messages.error')
        })

      this.updateTimer = setTimeout(() => {
        this.checkForUpdate(false)
      }, 60000)
    },
    doUpdate() {
      this.formSubmitted = true
      this.forceSWupdate()
    },
  },
}
</script>

<style>

</style>
