export default {
  methods: {
    forceSWupdate() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.getRegistrations().then(registrations => {
          registrations.forEach(registration => {
            registration.update()
          })

          setTimeout(success => {
            console.log(success)
            this.clearCache()
          }, 5000)
        })
      }
    },
    clearCache(reloadAfterClear = true) {
      if ('caches' in window) {
        caches.keys().then(names => {
          names.forEach(async name => {
            await caches.delete(name)
          })
        })

        if (reloadAfterClear) {
          window.location.reload()
        }
      }

      this.formSubmitted = false
    },
  },
}
